.heart {
  color: #e00;
  animation: beat .25s infinite alternate;
  transform-origin: center;
  stroke:  white;
  stroke-width: 2px;
}

/* Heart beat animation */
@keyframes beat{
  to { transform: scale(1.25); }
}