@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotating-circle {
    transform-origin: center;
    animation: rotate 2s linear infinite;
  }