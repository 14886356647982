.glow-effect {
  animation: pulse-glow 1.5s infinite;
}

@keyframes pulse-glow {
  0%, 100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4);
  }
  50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 40px rgba(255, 255, 255, 0.8), 0 0 60px rgba(255, 255, 255, 0.6);
  }
}

.glow-effect-circle {
  animation: pulse-glow 1.5s infinite;
  border-radius: 50%;

}



/* moving list item */
.list-container {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
}

/* List Item Styling */
.move-item {
  position: absolute;
  background-color: #65558F;
  color: white;

  /* Animation */
  animation: moveToTop 5s linear 1s infinite;
  margin-left: 20px;
}
.move-down {
  animation: moveDown 5s ease-out 1s infinite;
  margin-left: 20px;
}
.list-item {
  margin-left: 20px;
}

/* Keyframes to Move Item from Bottom to Top */
@keyframes moveToTop {
  0% {
    bottom: 0;
  }
  60% {
    bottom: 130px;
  }
  100% {
    bottom: 130px;
  }
}
@keyframes moveDown {
  0% {
    margin-top: 10px;
  }
  40% {
    margin-top: 10px;
  }
  70% {
    margin-top: 80px;
  }
  100% {
    margin-top: 80px;
  }
}